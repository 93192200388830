
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { useEffect, useRef, useState } from 'react';
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  XMarkIcon,
  ExclamationTriangleIcon,
  AdjustmentsVerticalIcon,
} from '@heroicons/react/24/solid';
import {
  Layout,
  TimeslotsTable,
  SubscriptionWarning,
  CalendarFilters,
  SlideOver,
} from '../components';
import {
  format,
  getWeek,
  getYear,
  isBefore,
  isEqual,
  isToday,
  getDayOfYear,
} from 'date-fns';
import { withPageAuthRequired } from '@auth0/nextjs-auth0';
import { useRouter } from 'next/router';
import { axiosInst, dateWithTz } from '../helpers';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { el } from 'date-fns/locale';
import { SessionWithCustomer } from './api/auth/[...auth0]';
import useSWR from 'swr';

export function populateEnhancedProfileWarningMessage(userEnhanced, t) {
  let strings = [];
  if (!userEnhanced?.firstname) {
    strings.push(t('common:firstName'));
  }
  if (!userEnhanced?.lastname) {
    strings.push(t('common:lastName'));
  }
  if (!userEnhanced?.phoneNumber) {
    strings.push(t('common:phone-number'));
  }
  if (!userEnhanced?.dateOfBirth) {
    strings.push(t('common:date-of-birth'));
  }
  if (!userEnhanced?.location) {
    strings.push(t('common:location'));
  }
  if (!userEnhanced?.emergencyContact) {
    strings.push(t('common:emergency-contact'));
  }
  return strings.join(', ');
}

export const fetcher = (url: string) =>
  axiosInst.get(url).then((res) => res.data);

function useCalendar(calendarPath: string) {
  const address = `/calendars/${calendarPath}`;
  const { data, error, isValidating } = useSWR(address, fetcher);

  return {
    data,
    isValidating,
    isLoading: !error && !data,
    isError: error,
  };
}

function useSessionTypes() {
  const address = `/session-types`;
  const { data, error, isValidating } = useSWR(address, fetcher);

  return {
    data,
    isValidating,
    isLoading: !error && !data,
    isError: error,
  };
}

function useLastSubscription() {
  const address = `/subscriptions/last`;
  const { data, error, isValidating } = useSWR(address, fetcher);

  return {
    data,
    isValidating,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useUserEnhanced(user) {
  const address = `/users`;
  const { data, error, isValidating, mutate } = useSWR(address, fetcher);

  return {
    data: { ...data, ...user },
    isValidating,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export function UserProfileWarning({
  userEnhanced,
  t,
  setEnhancedProfileWarning,
}) {
  return (
    <div className="bg-yellow-50 rounded-md p-4 fixed sm:relative mr-4 z-10 sm:z-0 sm:w-full sm:mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex flex-col space-y-4 md:space-y-0">
          <p className="text-sm text-yellow-700 mr-4">
            Please complete your profile details.
          </p>
          <div className="text-sm text-gray-500">
            {populateEnhancedProfileWarningMessage(userEnhanced, t)}
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={() => setEnhancedProfileWarning(false)}
              className="inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function CalendarPage({ user }: { user: SessionWithCustomer }) {
  const router = useRouter();
  const weekFromRouter = router.query?.week as string;
  const daysFromRouter = router.query?.days as string;
  const sessionTypesFromRouter = router.query?.sessionTypes as string;
  const [enhancedProfileWarning, setEnhancedProfileWarning] = useState(true);
  const [subscriptionEndsWarning, setSubscriptionEndsWarning] = useState(true);
  const [openSlideOver, setOpenSlideOver] = useState(false);

  const today = dateWithTz();
  const year = weekFromRouter
    ? Number(weekFromRouter.split('-W')[0])
    : getYear(today);
  const week = weekFromRouter
    ? Number(weekFromRouter.split('-W')[1])
    : getWeek(today);

  const [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState('');
  const [lastDayOfTheWeek, setLastDayOfTheWeek] = useState('');
  const [userEnhanced, setUserEnhanced] = useState(null);
  const [userEnhancedLoading, setUserEnhancedLoading] = useState(null);
  const [verificationCodeSubmitted, setVerificationCodeSubmitted] =
    useState(null);
  const { register, handleSubmit } = useForm();

  const [filteredDays, setFilteredDays] = useState(
    daysFromRouter?.split(',') || []
  );

  const { data: sessionTypes } = useSessionTypes();

  const [filteredSessionTypes, setFilteredSessionTypes] = useState(
    sessionTypesFromRouter?.split(',') || []
  );

  if (!user.customer) {
    router.push('/api/auth/logout');
  }

  const [calendarPath, setCalendarPath] = useState(`${year}-W${week}`);

  let url =
    filteredDays.length > 0
      ? `${calendarPath}&days=${filteredDays}`
      : calendarPath;

  url =
    filteredSessionTypes.length > 0
      ? `${url}&sessionTypes=${filteredSessionTypes}`
      : url;

  const { data: calendar, isLoading, isValidating } = useCalendar(url);

  const { data: lastSubscription, isValidating: isLastSubscriptionValidating } =
    useLastSubscription();

  async function fetchUser() {
    setUserEnhancedLoading(true);
    try {
      const { data } = await axiosInst.get(`/users`);
      setUserEnhanced(data);
    } catch (error) {
      console.log(error);
    }
    setUserEnhancedLoading(false);
  }

  useEffect(() => {
    async function fetch() {
      await fetchUser();
    }

    fetch();
  }, []);

  const [hash, setHash] = useState(format(today, 'EEEE').toLowerCase());

  useEffect(() => {
    router.push({
      query: {
        week: calendarPath,
        ...(filteredDays.length > 0 && { days: filteredDays.join(',') }),
        ...(filteredSessionTypes.length > 0 && {
          sessionTypes: filteredSessionTypes.join(','),
        }),
      },
      hash,
    });
  }, [filteredDays, calendarPath, hash, filteredSessionTypes]);

  const currentDate = useRef<HTMLInputElement>(null);

  useEffect(() => {
    currentDate.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [currentDate.current]);

  function handleTodayBtn() {
    setCalendarPath(`${getYear(today)}-W${getWeek(today)}`);
    setHash(format(today, 'EEEE').toLowerCase());
  }

  useEffect(() => {
    if (calendar?.weeks?.currentWeek?.days) {
      const daysCopy = [...calendar?.weeks?.currentWeek?.days];
      const [lastDay, ...rest] = [...daysCopy.reverse()];
      const firstDayDate =
        calendar?.weeks?.currentWeek?.days.length > 0
          ? dateWithTz(calendar?.weeks?.currentWeek?.days[0]?.date)
          : null;
      const lastDayDate = lastDay?.date ? dateWithTz(lastDay?.date) : null;
      setFirstDayOfTheWeek(
        firstDayDate
          ? format(firstDayDate, 'EE dd LLL', lang === 'el' && { locale: el })
          : ''
      );
      setLastDayOfTheWeek(
        lastDayDate
          ? format(lastDayDate, 'EE dd LLL', lang === 'el' && { locale: el })
          : ''
      );
    } else {
      setFirstDayOfTheWeek('');
      setLastDayOfTheWeek('');
    }
  }, [calendar?.weeks?.currentWeek?.days]);

  async function handleSubmitPhoneNumber({ phoneNumber }) {
    try {
      const { data } = await axiosInst.patch(`/users`, {
        phoneNumber,
      });
      setUserEnhanced(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmitPhoneNumberConfirmation() {
    try {
      await axiosInst.post(`sms/initiate-verification`);
      setVerificationCodeSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSubmitVerificationCodeConfirmation({
    confirmVerificationCode,
  }) {
    try {
      const { data } = await axiosInst.post(`sms/check-verification-code`, {
        code: confirmVerificationCode,
      });
      setUserEnhanced(data);
    } catch (error) {
      console.log(error);
    }
  }

  const { t, lang } = useTranslation('calendar');

  return (
    <Layout
      title="Calendar | slotit.io - book your slots"
      company={user.customer.company}
    >
      <>
        <SlideOver
          setOpenSlideOver={setOpenSlideOver}
          openSlideOver={openSlideOver}
        >
          {calendarPath && (
            <CalendarFilters
              filteredDays={filteredDays}
              setFilteredDays={setFilteredDays}
              filteredSessionTypes={filteredSessionTypes}
              setFilteredSessionTypes={setFilteredSessionTypes}
              sessionTypes={sessionTypes}
              setOpenSlideOver={setOpenSlideOver}
            />
          )}
        </SlideOver>
        <div className="pb-12">
          <div className="mb-6">
            <div className="mb-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {t('book-your-slot')}
              </h3>
              <div className="sm:hidden">
                <div className="text-sm mr-2 font-medium text-gray-500">
                  {firstDayOfTheWeek} - {lastDayOfTheWeek}
                </div>
                <h3 className="text-xl leading-6 font-medium text-gray-900">
                  {calendar?.weeks?.currentWeek?.name}
                </h3>
              </div>
              <p className="hidden sm:block mt-1 max-w-2xl text-sm text-gray-500">
                {t('calendar-details-and-application')}
              </p>
            </div>
            {!userEnhancedLoading && (
              <div className="space-y-4 -ml-4 sm:ml-0">
                {lastSubscription && (
                  <SubscriptionWarning
                    subscription={lastSubscription}
                    company={user.customer.company}
                    subscriptionEndsWarning={subscriptionEndsWarning}
                    handleSubscriptionEndsWarning={() =>
                      setSubscriptionEndsWarning(false)
                    }
                  />
                )}
                <>
                  {enhancedProfileWarning &&
                    (!userEnhanced?.firstname ||
                      !userEnhanced?.lastname ||
                      !userEnhanced?.phoneNumber ||
                      !userEnhanced?.dateOfBirth ||
                      !userEnhanced?.location) && (
                      <UserProfileWarning
                        userEnhanced={userEnhanced}
                        t={t}
                        setEnhancedProfileWarning={setEnhancedProfileWarning}
                      />
                    )}
                </>

                {!userEnhanced?.phoneNumber && (
                  <div className="bg-yellow-50 rounded-md p-4 fixed sm:relative z-10 sm:z-0 w-full">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 flex flex-col space-y-4 md:flex-row md:space-y-0">
                        <p className="text-sm text-yellow-700 mr-4">
                          {t('phone-number-to-book')}
                        </p>
                        <div className="flex">
                          <label htmlFor="location" className="sr-only">
                            {t('add-phone-number')}
                          </label>
                          <input
                            type="tel"
                            name="phoneNumber"
                            id="phoneNumber"
                            {...register('phoneNumber')}
                            className="w-32 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300 rounded-md"
                          />
                          <button
                            onClick={handleSubmit(handleSubmitPhoneNumber)}
                            type="button"
                            className="ml-4 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            {t('add-phone')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {userEnhanced?.phoneNumber &&
                  !userEnhanced?.isPhoneNumberConfirmed &&
                  !verificationCodeSubmitted && (
                    <div className="bg-yellow-50 rounded-md p-4 fixed sm:relative z-10 sm:z-0 w-full">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon
                            className="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 flex flex-col space-y-4 md:flex-row md:space-y-0">
                          <p className="text-sm text-yellow-700 mr-4 max-w-sm">
                            {t('confirm-phone-number')}
                            <span className="block">
                              {t('click-send-code')}
                            </span>
                          </p>
                          <div>
                            <div className="flex">
                              <label htmlFor="location" className="sr-only">
                                {t('send-verification-code')}
                              </label>
                              <input
                                type="text"
                                name="confirmPhoneNumber"
                                id="confirmPhoneNumber"
                                readOnly
                                value={userEnhanced.phoneNumber}
                                {...register('confirmPhoneNumber')}
                                className="w-32 shadow-sm block border-gray-300 rounded-md opacity-50 cursor-not-allowed"
                              />
                              <button
                                onClick={handleSubmit(
                                  handleSubmitPhoneNumberConfirmation
                                )}
                                type="button"
                                className="ml-4 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                {t('send-code')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {userEnhanced?.phoneNumber &&
                  !userEnhanced?.isPhoneNumberConfirmed &&
                  verificationCodeSubmitted && (
                    <div className="bg-yellow-50 rounded-md p-4 fixed sm:relative z-10 sm:z-0 w-full">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon
                            className="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 flex flex-col space-y-4 md:flex-row md:space-y-0">
                          <p className="text-sm text-yellow-700 mr-4 max-w-sm">
                            {t('confirm-verification-code')}{' '}
                            <span className="block">
                              {t('add-the-SMS-code')}
                            </span>
                          </p>
                          <div className="flex">
                            <label htmlFor="location" className="sr-only">
                              {t('confirm-verification-code')}
                            </label>
                            <input
                              type="text"
                              name="confirmVerificationCode"
                              id="confirmVerificationCode"
                              {...register('confirmVerificationCode')}
                              className="w-32 shadow-sm block border-gray-300 rounded-md opacity-50"
                            />
                            <button
                              onClick={handleSubmit(
                                handleSubmitVerificationCodeConfirmation
                              )}
                              type="button"
                              className="ml-4 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              {t('submit-code')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>

          <div className="hidden sm:flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-4 mb-6">
            <div className="flex lg:w-48 space-x-4">
              <div>
                <div className="text-sm mr-2 font-medium text-gray-500">
                  {firstDayOfTheWeek} - {lastDayOfTheWeek}
                </div>
                <h3 className="text-xl leading-6 font-medium text-gray-900">
                  {calendar?.weeks?.currentWeek?.name}
                </h3>
              </div>
            </div>
            <div className="space-x-4 hidden sm:flex">
              <button
                onClick={() => handleTodayBtn()}
                type="button"
                className="w-1/3 lg:w-36 h-12 px-3 border border-transparent shadow-sm text-base leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t('today')}
              </button>
            </div>

            <div className="items-center space-x-4 xl:w-96 hidden sm:flex">
              <button
                onClick={() => {
                  setCalendarPath(`${calendar?.weeks?.previousWeek?.name}`);
                  setHash('');
                }}
                type="button"
                disabled={isLoading || !calendar?.weeks?.previousWeek?.name}
                className="w-1/2 inline-flex justify-center items-center px-3 h-12 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <ChevronLeftIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                {t('previous-week')}
              </button>
              <button
                onClick={() => {
                  setCalendarPath(`${calendar?.weeks?.nextWeek?.name}`);
                  setHash('');
                }}
                type="button"
                disabled={isLoading || !calendar?.weeks?.nextWeek?.name}
                className="w-1/2 inline-flex justify-center items-center px-3 h-12 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t('next-week')}
                <ChevronRightIcon
                  className="ml-2 -mr-0.5 h-4 w-4"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>

          {calendar?.weeks && !calendar?.weeks?.currentWeek?.enabled && (
            <div className="mb-8">
              <div className="bg-red-50 rounded-md p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XMarkIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 flex flex-col space-y-4 md:flex-row md:space-y-0">
                    <p className="text-sm text-red-700 mr-4">
                      {t('no-available-week')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isLoading &&
            !isValidating &&
            calendar?.weeks?.currentWeek?.enabled && (
              <div key={calendar?.weeks?.currentWeek?.id} className="mx-auto">
                <div className="space-y-6">
                  {calendar?.weeks?.currentWeek?.days?.map((day, dayIndex) => {
                    return (
                      <div
                        ref={
                          isEqual(
                            getDayOfYear(dateWithTz(day?.date)),
                            getDayOfYear(today)
                          )
                            ? currentDate
                            : null
                        }
                        id={format(dateWithTz(day?.date), 'EEEE').toLowerCase()}
                        key={dayIndex}
                        className={clsx({
                          'border-2 rounded-lg bg-gray-50': true,
                          'opacity-50':
                            isBefore(dateWithTz(day.date), dateWithTz(today)) &&
                            !isToday(dateWithTz(day.date)),
                          'border-indigo-500 opacity-100': isToday(
                            dateWithTz(day.date)
                          ),
                          'border-gray-200': !isToday(dateWithTz(day.date)),
                        })}
                      >
                        <div className="px-4 py-6 flex justify-between border-b">
                          <div>
                            <div className="hidden sm:block text-sm mr-2 font-medium text-gray-500">
                              {t('date')}
                            </div>
                            <h3 className="text-lg sm:text-2xl leading-6 font-medium text-gray-900">
                              {format(
                                dateWithTz(day?.date),
                                'EEEE dd LLLL yyyy',
                                lang === 'el' && {
                                  locale: el,
                                }
                              )}
                            </h3>
                          </div>
                        </div>
                        <TimeslotsTable
                          timeslots={day?.timeslots}
                          companyId={user?.customer?.company?.id}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
        </div>
        <div className="flex items-center fixed bottom-0 left-0 right-0 z-10 space-x-0.5 bg-gray-200 border-t-2">
          <button
            type="button"
            className="rounded-full bg-indigo-600 p-5 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 absolute bottom-0 right-0 mb-16 sm:mb-12 mr-6"
            onClick={() => setOpenSlideOver(true)}
          >
            <AdjustmentsVerticalIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <button
            onClick={() => {
              setCalendarPath(`${calendar?.weeks?.previousWeek?.name}`);
              setHash('');
            }}
            type="button"
            disabled={isLoading || !calendar?.weeks?.previousWeek?.name}
            className="w-full sm:hidden inline-flex justify-center items-center px-3 h-12 border border-transparent shadow-sm text-sm leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
          >
            <ChevronLeftIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
          </button>
          <button
            onClick={() => handleTodayBtn()}
            type="button"
            className="w-full sm:hidden h-12 px-3 border border-transparent shadow-sm text-sm leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
          >
            {t('today')}
          </button>
          <button
            onClick={() => {
              setCalendarPath(`${calendar?.weeks?.nextWeek?.name}`);
              setHash('');
            }}
            type="button"
            disabled={isLoading || !calendar?.weeks?.nextWeek?.name}
            className="w-full sm:hidden inline-flex justify-center items-center px-3 h-12 border border-transparent shadow-sm text-sm leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
          >
            <ChevronRightIcon
              className="ml-2 -mr-0.5 h-4 w-4"
              aria-hidden="true"
            />
          </button>
        </div>
      </>
    </Layout>
  );
}

const __Page_Next_Translate__ = withPageAuthRequired(CalendarPage);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  